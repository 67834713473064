import { trackGenericEvent } from '@/utils/analytics';
import { Column } from '@/components/Column';
import { Row } from '@/components/Row';
import { useScreenSize } from '@/hooks';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  onCTA: (section: string) => void;
}
const CTA: React.FC<Props> = ({ onCTA }) => {
  const { isMobile } = useScreenSize();

  return (
    <Column
      as="section"
      className="justify-center bg-landing-cta gap-5 sm:gap-10 items-center py-16 sm:rounded-[20px] px-4 text-white"
    >
      <h2 className="text-xl sm:text-4xl font-medium text-center">
        ¿Qué esperas para empezar <br /> a vivir de lo que amas?
      </h2>
      <Row
        align="center"
        gap={5}
        justify="space-between"
        className="w-full xl:w-[60%] bg-white py-2 pr-2 rounded-lg shadow-[0px_4px_25px_0px_rgba(0,0,0,0.61)]"
      >
        <Row align="center" className="text-black pl-6 w-[80%] xl:w-[60%]">
          <span className="font-bold text-base">agora.red/</span>
          <input
            placeholder="tunombre"
            className="text-base w-full"
            onBlur={(e) => {
              trackGenericEvent('Input Username Landing Page Filled', { value: e.target.value });
            }}
          />
        </Row>
        <Row
          align="center"
          className="w-auto bg-[#0072FF] text-white rounded-lg px-4 py-2 cursor-pointer hover:bg-[#014AA4]"
          onClick={() => onCTA('Footer CTA')}
        >
          <span className="hidden sm:inline">Crear tienda</span>
          <FontAwesomeIcon
            className="ml-0 sm:ml-2 mb-0 sm:-mb-0.5"
            icon={faArrowRight}
            size={isMobile ? 'sm' : 'lg'}
          />
        </Row>
      </Row>
    </Column>
  );
};

export default CTA;
